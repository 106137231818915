import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyDMHt3LJTR0OuET59U_OIWaV_cBX6OaaJE',
  authDomain: 'stereo-a508d.firebaseapp.com',
  databaseURL: 'https://stereo-a508d.firebaseio.com',
  projectId: 'stereo-a508d',
  storageBucket: 'stereo-a508d.appspot.com',
  messagingSenderId: '711190122060',
  appId: '1:711190122060:web:1b6f52c0baf3a1e89c6ee9',
  measurementId: 'G-SVHTNVDKST',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    firebase.analytics()
  }
}

export default firebase
